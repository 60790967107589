import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import { USER_LIST , DISEASE , SYMPTOM, DISTINGUISH , SUBMIT, PATIENT , VISIT , DIV } from "./actionTypes";
import { users , disease_name , get_symptoms , get_distinguish_symptoms , submit , new_user , list , symptoms } from "../../utils/api";

// 发起用户列表请求
export function* getUserList(active) {
    const result = yield axios(`/userapi/${users}`,active.params);
    if(result.code===1){
        const user = result.data;
        // 派发给reducers
        yield put({
            type: USER_LIST.GET_LIST,
            user
        })
    }
}

// 搜索病名
export function* getDiseaseName(active) {
    const result = yield axios(`/kfApi/${disease_name}`,active.params)
    if(result.code===1){
        const data = result.data;
        yield put({
            type: DISEASE.GET_DISEASE_NAME,
            data
        }) 
    }
}

// 根据病名或主诉获取症状列表(用于单选)
export function* getSymptom(active) {
    const result = yield axios(`/kfApi/${get_symptoms}`,active.params,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SYMPTOM.POST_SYMPTOM,
            data
        }) 
    }
}

// 根据单个症状id获取辨别症状列表(用于多选)
export function* getDistinguish(active) {
    const result = yield axios(`/kfApi/${get_distinguish_symptoms}`,active.params)
    if(result.code===1){
        const data = result.data;
        yield put({
            type: DISTINGUISH.GET_DISTINGUISH,
            data
        }) 
    }
}

//提交报告
export function* getSubmit(active) {
    const result = yield axios(`/kfApi/${submit}`,active.params,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SUBMIT.POST_SUBMIT_REPORT,
            data
        })
    }
}

//新增患者
export function* getAddUser(active) {
    const result = yield axios(`/userapi/${new_user}`,active.params,'POST')
    if(result.code===1){
        const data = result;
        yield put({
            type: PATIENT.GET_PATIENT_USER,
            data
        })
    }
}

//就诊记录
export function* getVisit(active) {
    const result = yield axios(`/kfApi/${list}`,active.params)
    const data = result.data;
    if(result.code===1){
        yield put({
            type: VISIT.GET_VISIT_RECORD,
            data
        }) 
    }
}

//自定义病症
export function* getDivSubmit(active) {
    const result = yield axios(`/kfApi/${symptoms}`,active.params.form,'POST');
    if(result.code===1){
        const data = result.data;
        if(active.params.data.symptom_id){
            active.params.data.symptom_list.push(data.id);
        }else{
            active.params.data.symptom_id=data.id;
        }
        yield put({
            type: DIV.POST_DIV_SUBMIT,
            params:active.params.data
        }) 
    }
}