//请求
import { takeEvery } from 'redux-saga/effects'
//诊断
import { USER_LIST , DISEASE , SYMPTOM, DISTINGUISH , SUBMIT , PATIENT , VISIT , DIV } from "./home/actionTypes";
import { getUserList , getDiseaseName , getSymptom , getDistinguish , getSubmit , getAddUser , getVisit , getDivSubmit } from "./home/sagas";
//登录
import { LOGIN , CODE } from "./login/actionTypes";
import { postSubmit , getCode } from "./login/sagas";
//报告
import { REPORT , COMPLETE , SEARCH } from "./report/actionTypes";
import { getReport , getComplete , getSearch } from "./report/sagas";

function* mySaga() {
    // 接受action传递的任务
    yield takeEvery(USER_LIST.LIST, getUserList);
    yield takeEvery(LOGIN.LOGIN_SUBMIT, postSubmit);
    yield takeEvery(CODE.CODE_IMG, getCode);
    yield takeEvery(DISEASE.DISEASE_NAME, getDiseaseName);
    yield takeEvery(SYMPTOM.SYMPTOM_LOOK, getSymptom);
    yield takeEvery(DISTINGUISH.DISTINGUISH_LOOK, getDistinguish);
    yield takeEvery(SUBMIT.SUBMIT_REPORT, getSubmit);
    yield takeEvery(REPORT.REPORT_INFO, getReport);
    yield takeEvery(COMPLETE.COMPLETE_REPORT, getComplete);
    yield takeEvery(PATIENT.PATIENT_USER, getAddUser);
    yield takeEvery(SEARCH.SEARCH_ING, getSearch);
    yield takeEvery(VISIT.VISIT_RECORD, getVisit);
    yield takeEvery(DIV.DIV_SUBMIT, getDivSubmit);
    yield takeEvery(DIV.POST_DIV_SUBMIT, getSubmit);
}

export default mySaga;