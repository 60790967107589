//登录
export const admin_login = "admin/login";
//验证码
export const code_img = "captcha";
//用户列表
export const users = "new_customer";
//病名列表
export const disease_name = "config/zzbkf_disease_name";
//根据主诉和病名找症状（单选）
export const get_symptoms = "zzbkf/get_symptoms";
//根据单个症状id获取辨别症状列表（多选）
export const get_distinguish_symptoms = "zzbkf/get_distinguish_symptoms";
//提交报告
export const submit = "zzbkf/submit";
//报告详情
export const report_info = "zzbkf/info";
//完诊
export const final = "zzbkf/final";
//就诊记录
export const list = "zzbkf/list";
//新建用户
export const new_user = "new_user";
//相关搜索
export const search = "config/search";
//相关搜索
export const symptoms = "zzbkf/add_symptoms";