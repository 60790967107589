import axios from "axios";

//请求超时时间
axios.defaults.timeout = 10000;

//post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//配置请求拦截器
axios.interceptors.request.use((config)=>{
    //统一加token
    if(localStorage.token){
        config.headers['Authorization'] = `${localStorage.token}`;
    }
    return config;
},(error)=>{
    return Promise.error(error);
});


//配置响应拦截器
axios.interceptors.response.use((response)=>{
    //状态过滤
    if(response.status === 200){
        return Promise.resolve(response.data);
    }else{
        return Promise.reject(response.data);
    }
},(error)=>{
    if(error.response && error.response.status === 401){
        //跳转登录
        window.location.href='/login';
        return Promise.reject(error.response.data);
    }else{
        return Promise.reject({
            code:0,
            msg:'请求失败！'
        });
    }
})

//请求方法
export default function ajax( url = '' , params = {} , type='GET' , token = false ){
    return  new Promise((resolve,reject)=>{
        let promis;
        //判断请求类型
        if(type.toUpperCase()==='GET'){           
            promis = axios({
                url,
                params,
                token
            })
        }else if(type.toUpperCase()==='POST'){
            promis = axios({
                method:'post',
                url,
                data:params,
                token
            })
        }
        //处理结果
        promis.then((response)=>{
            resolve(response);
        }).catch((error)=>{
            reject(error);
        })
    })

}