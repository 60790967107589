import { combineReducers } from 'redux';

import homeReducer from './home/reducer';
import loginReducer from './login/reducer';
import reportReducer from './report/reducer';

const Reducer = combineReducers({
    homeReducer,
    loginReducer,
    reportReducer
});

export default Reducer;
  