import {
    USER_LIST,
    CLICKID,
    DISEASE,
    PROPSVALUE,
    SYMPTOM,
    DISTINGUISH,
    SUBMIT,
    PATIENT,
    VISIT
} from './actionTypes'
import { message } from 'antd';

// 数据池
const defaultState = {
    users: [],      //用户列表
    user:null,      //选中
    illness:[],     //疾病列表
    select:[],      //id组
    symptom:[],     //单选病证
    distinguish:{
        loading:false,
        data:[]
    },               //多选病证
    visit:[],
    visit_one:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case USER_LIST.GET_LIST:          //请求数据
            if(action.user.Data){
                newState.users = action.user.Data;
            }else{
                newState.users = [];
            }
            return newState;
        case CLICKID:          //选择用户列表
            for(let i=0,s=newState.users.length;i<s;i++){
                if(action.id === newState.users[i].id){
                    newState.users[i].active = true;
                    newState.user = newState.users[i];
                }else{
                    newState.users[i].active = false;
                }
            }
            return newState;
        case DISEASE.GET_DISEASE_NAME:     //搜索病例
            newState.illness = action.data;
            return newState;
        case PROPSVALUE:                   //修改状态
            newState[action.params.key] = action.params.value;
            return newState;
        case SYMPTOM.POST_SYMPTOM:         //根据主诉和病名找症状（单选）
            if(newState.visit_one){
                action.data.map((data)=>{
                    if(data.id===newState.visit_one.symptom.id){
                        data.active = true;
                    }
                })
            }
            newState.symptom = action.data;
            return newState;
        case DISTINGUISH.GET_DISTINGUISH: //根据单个症状id获取辨别症状列表（多选）
            if(newState.visit_one){
                action.data.map((data)=>{
                    newState.visit_one.symptom_list.map((one)=>{
                        if(data.id===one.id){
                            data.active = true;
                        }
                    })
                })
            }
            newState.distinguish = {
                loading:true,
                data:action.data
            };
            setTimeout(function(){
                let top = document.getElementById("question").offsetTop;
                document.getElementById("suit").scrollTop=top;
            },1);
            return newState;
        case SUBMIT.POST_SUBMIT_REPORT:   //提交报告
            sessionStorage.setItem('user',JSON.stringify(newState.user));
            window.location.href=`/report/${action.data.report_token}`;
            return newState;
        case PATIENT.GET_PATIENT_USER:   //新增修改患者
            if(action.data.code===0){
                message.error(action.data.msg);
            }else{
                message.success(action.data.msg);
                setTimeout(function(){
                    window.location.reload();
                },1000);
            }
            return newState;
        case VISIT.GET_VISIT_RECORD:     //就诊记录
            newState.visit = action.data.list;
            return newState;
        default:
            return state;
    }
};

export default Reducer;
